function isValid(email) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    return pattern.test(email);
}

function ajaxCall(url_to_call, data_array) {
    var response = 
    $.ajax({
        type: "POST",
        url: url_to_call,
        data: data_array,
        async: false,
        success: function(result) {
            return result;
        }
    }).responseText;
    return response;
}

$(function() {
	$('.add-to-favourites').click(function() {
		window.location.href = 'add_to_favourites.php?item=' + $(this).attr('data-item');
	});
});