// table - open
var table_opener = document.querySelectorAll('[data-table]');

if (table_opener.length) {
    for (var i = 0; i < table_opener.length; i++) {
        var btn = table_opener[i];

        btn.addEventListener('click', function(event) {
            event.preventDefault();
            toggleClass(this, 'open');
            toggleClass(this.parentNode.parentNode, 'open-tr');

            var target = event.target.dataset.table;

            if (hasClass(this, 'open')) {
                addContent(this, 'Becsuk');

                if (target) {
                    var table_id = document.getElementById('data-table-' + target)
                    table_id.style.display = 'table-row';
                }
            } else {
                addContent(this, 'Részletek');
                if (target) {
                    var table_id = document.getElementById('data-table-' + target)
                    table_id.style.display = 'none';
                }
            }
        });
    }
}