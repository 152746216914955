// modal - open
var modal_btns = document.querySelectorAll('[data-modal]');

if (modal_btns.length) {
    for (var i = 0; i < modal_btns.length; i++) {
        var btn = modal_btns[i];

        btn.addEventListener('click', function(event) {
            event.preventDefault();

            //var target = this.dataset.modal;
            var target = event.target.dataset.modal;
            if (target == 'warehouse-selector') {
                var response = 
                $.ajax({
                    type: "POST",
                    url: "get_stock.php",
                    data: { item: this.dataset.item, qtty: $('#qtty-' + this.dataset.item).val() },
                    async: false,
                    success: function(result) {
                        return result;
                    }
                }).responseText;
                $('#modal-storage-content').html(response);
            } else {//recaptcha
                document.getElementById('recaptcha-item').value = this.dataset.item;
            }
            document.getElementById('modal-' + target).style.display = 'block';
        });
    }
}

// modal - close
var modal_close_btn = document.querySelectorAll('[data-modal-close]');

if (modal_close_btn) {
    for (var i = 0; i < modal_close_btn.length; i++) {
        var close_btn = modal_close_btn[i];

        close_btn.addEventListener('click', function(event) {
            event.preventDefault();
            var modals = document.getElementsByClassName('modal-container');

             for (var i = 0; i < modals.length; i++) {
                modals[i].style.display = 'none';
             }
        });
    }
}