var hasError = function(input, errormsg) {
    addClass(input.parentElement, 'has-error');

    var span = document.createElement('span');
    span.className = 'error-label';
    span.innerHTML = errormsg;
    input.parentNode.insertBefore(span, input.nextSibling);

    input.addEventListener('click', function(e) {
        removeClass(this.parentElement, 'has-error');
        var error_label = this.parentElement.querySelector('.error-label');
        if (error_label) {
            error_label.remove();
        }
    });
};

// contact form
var form = document.getElementById('contact-form');

if (form) {
    form.addEventListener('submit', function(e) {

        var errors = [];
        var inputs = form.querySelectorAll('.form-input');

        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].required && inputs[i].value == '') {
                hasError(inputs[i], 'Kötelező mező!');

                errors.push(1);
            } else {
                if (inputs[i].value.length) {

                    if (inputs[i].type == 'email' && !isValid(inputs[i].value)) {
                        hasError(inputs[i], 'Helytelen email!');
                        errors.push(1);
                    }

                    if (inputs[i].type == 'number' && isNaN(inputs[i].value)) {
                        hasError(inputs[i], 'Helytelen szám!');
                        errors.push(1);
                    }
                }
            }
        }

        if (errors.length) {
            console.log(errors);
            e.preventDefault();
            return false;
        }
        return true;
    });
}

// delivery copy
// var copy_btn = document.getElementById('delivery-copy');

// if (copy_btn) {
//     copy_btn.addEventListener('click', function(e) {
//         e.preventDefault();

//         var billing_datas = document.querySelectorAll('[data-related]');

//         if (billing_datas) {
//             for (var i = 0; i < billing_datas.length; i++) {

//                 var billing_data = billing_datas[i];
//                 var billing_id = billing_data.dataset.related;
//                 var billing_field = document.getElementById(billing_id);

//                 if (billing_field) {
//                     if (billing_field.type == 'select-one') {
//                         billing_data.selectedIndex = billing_field.selectedIndex;
//                     } else {
//                         billing_data.value = billing_field.value;
//                     }
//                 }
//             }
//         }
//     });
// }

// section open
var open_btns = document.querySelectorAll('.section-opener');

if (open_btns) {
    for (var i = 0; i < open_btns.length; i++) {

        var open_btn = open_btns[i];

        open_btn.addEventListener('click', function(e) {
            e.preventDefault();

            var section = this.parentElement.parentElement;
            addClass(section, 'opened-section');
        });
    }
}