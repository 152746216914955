var forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]);
    }
};

var showElem = function(elem) {
    if (elem) {
        elem.style.display = 'block';
    }
};

var showByClass = function(cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function (index, elem) {
            elem.style.display = 'block';
        });
    }
};

var hideElem = function(elem) {
    elem.style.display = 'none';
};

var hideByClass = function(cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function (index, elem) {
            elem.style.display = 'none';
        });
    }
};

var toggleElem = function(elem) {
    if (elem.style.display == 'block') {
        elem.style.display = 'none';
    } else {
        elem.style.display = 'block';
    }
};

var toggleByClass = function(cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function (index, elem) {
            if (elem.style.display == 'block') {
                elem.style.display = 'none';
            } else {
                elem.style.display = 'block';
            }
        });
    }
};

var hasClass = function(elem, cl) {
    return new RegExp(' ' + cl + ' ').test(' ' + elem.className + ' ');
};

var addClass = function(selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector;

        if (!hasClass(elem, cl)) {
            elem.className == '' ? elem.className = cl : elem.className += ' ' + cl;
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function (index, elem) {
                if (!hasClass(elem, cl)) {
                    elem.className == '' ? elem.className = cl : elem.className += ' ' + cl;
                }
            });
        }
    }

    return null;
};

var removeClass = function(selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector;
        var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';

        if (hasClass(elem, cl)) {
            while (newcl.indexOf(' ' + cl + ' ') >= 0) {
                newcl = newcl.replace(' ' + cl + ' ', ' ');
            }
            elem.className = newcl.replace(/^\s+|\s+$/g, '');
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function (index, elem) {
                var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';

                if (hasClass(elem, cl)) {
                    while (newcl.indexOf(' ' + cl + ' ') >= 0) {
                        newcl = newcl.replace(' ' + cl + ' ', ' ');
                    }
                    elem.className = newcl.replace(/^\s+|\s+$/g, '');
                }
            });
        }
    }

    return null;
};

var toggleClass = function(selector, cl) {
    if (typeof selector === 'object') {
        if (selector.length) {
            forEach(selector, function(index, elem) {
                hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl);
            });
        } else {
            hasClass(selector, cl) ? removeClass(selector, cl) : addClass(selector, cl);
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function (index, elem) {
                hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl);
            });
        }
    }

    return null;
};

var addContent = function(selector, content) {
    if (typeof selector === 'object') {
        var elem = selector;

        elem.innerHTML = content;
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function (index, elem) {
                elem.innerHTML = content;
            });
        }
    }

    return null;
};