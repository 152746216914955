var slider = document.getElementById('range-slider1');

if (slider) {
    noUiSlider.create(slider, {
        start: [20, 80],
        connect: true,
        range: {
            'min': 0,
            'max': 100
        }
    });
}

var slider = document.getElementById('range-slider2');

if (slider) {
    noUiSlider.create(slider, {
        start: [20, 80],
        connect: true,
        range: {
            'min': 0,
            'max': 100
        }
    });
}

var specialCarousel = document.getElementById('special-offers');
    if (specialCarousel) {
        var specialFlkty = new Flickity(specialCarousel, {
            accessibility: true,
            // adaptiveHeight: true,
            autoPlay: 2000,
            // pauseAutoPlayOnHover: false,
            // cellAlign: 'left',
            // cellSelector: undefined,
            contain: true,
            draggable: true,
            dragThreshold: 3,
            freeScroll: true,
            friction: 0.3,
            groupCells: false,
            initialIndex: 0,
            lazyLoad: false,
            imagesLoaded: true,
            percentPosition: false,
            prevNextButtons: false,
            pageDots: false,
            // resize: true,
            wrapAround: true,
            // rightToLeft: false,
            // setGallerySize: true,
            // watchCSS: false,
        });

        // previous
        var previousButton = document.querySelector('.btn-special-prev');
        previousButton.addEventListener( 'click', function() {
            specialFlkty.previous();
        });

        // next
        var nextButton = document.querySelector('.btn-special-next');
        nextButton.addEventListener( 'click', function() {
            specialFlkty.next();
        });
    }

var recentCarousel = document.getElementById('recent-offers');
    if (recentCarousel) {
        var recentFlkty = new Flickity(recentCarousel, {
            accessibility: true,
            // adaptiveHeight: true,
            autoPlay: 2000,
            // pauseAutoPlayOnHover: false,
            // cellAlign: 'left',
            // cellSelector: undefined,
            contain: true,
            draggable: true,
            dragThreshold: 3,
            freeScroll: true,
            friction: 0.3,
            groupCells: false,
            initialIndex: 0,
            lazyLoad: false,
            imagesLoaded: true,
            percentPosition: false,
            prevNextButtons: false,
            pageDots: false,
            // resize: true,
            wrapAround: true,
            // rightToLeft: false,
            // setGallerySize: true,
            // watchCSS: false,
        });

        // previous
        var previousButton = document.querySelector('.btn-recent-prev');
        previousButton.addEventListener( 'click', function() {
            recentFlkty.previous();
        });

        // next
        var nextButton = document.querySelector('.btn-recent-next');
        nextButton.addEventListener( 'click', function() {
            recentFlkty.next();
        });
    }
