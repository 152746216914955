// toggle-menus
var trigger_btns = document.querySelectorAll('.submenu-trigger');

if (trigger_btns) {
    for (var i = 0; i < trigger_btns.length; i++) {

        var trigger_btn = trigger_btns[i];

        trigger_btn.addEventListener('click', function(e) {
            e.preventDefault();

            var section = this.nextElementSibling;
            toggleClass(section, 'submenu-open');

            var icon = this.querySelector('.fa');
            if (hasClass(icon, 'fa-minus')) {
                removeClass(icon, 'fa-minus');
                addClass(icon, 'fa-plus');
            } else {
                removeClass(icon, 'fa-plus');
                addClass(icon, 'fa-minus');
            }
        });
    }
}

// category-menus
// var menu_triggers = document.querySelectorAll('.category-level-item');
//
// if (menu_triggers) {
//     for (var i = 0; i < menu_triggers.length; i++) {
//
//         var menu_trigger = menu_triggers[i];
//
//         menu_trigger.addEventListener('click', function(e) {
//
//             var section = this.nextElementSibling;
//             if (section) {
//                 e.preventDefault();
//                 toggleClass(section, 'category-menu-level-open');
//             }
//
//             var icon = this.querySelector('.fa');
//             if (hasClass(icon, 'fa-minus')) {
//                 removeClass(icon, 'fa-minus');
//                 addClass(icon, 'fa-plus');
//             } else {
//                 removeClass(icon, 'fa-plus');
//                 addClass(icon, 'fa-minus');
//             }
//         });
//     }
// }

var level_toggle = document.getElementById('category-level-toggle');

if (level_toggle) {
    level_toggle.addEventListener('click', function(e) {
        e.preventDefault();

        var levels = document.querySelectorAll('.category-menu-level');
        toggleClass(levels, 'category-menu-level-open');

        toggleClass(this, 'open');
        hasClass(this, 'open') ? addContent(this, 'Mindent becsuk') : addContent(this, 'Mindent kinyit');
    });
}
