$(function() {

    $('html').removeClass('no-js');

    /* Featured images */
    if ($('#featured').length != 0) {
        $('#featured').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            dots: true,
            fade: true,
            speed: 1000,
            slide: '.slide',
            appendDots: $('.slider-dot-control')
        });
    }

    $('.menu-toggler').click(function(e) {
        e.preventDefault();
        $(this).parents('#header .header-links').find('.links').slideToggle(100);
        $(this).toggleClass('header-menu-open');
        $(this).parents('#submenu').find('.links').slideToggle(100);
    });

    $('.filter-header').click(function() {
        $(this).parents('.filter-container').find('.filter-control').slideToggle(100);
        $(this).parents('.filter-container').find('.slide-trigger i').toggleClass('icon-plus');
    });

    if ($(window).width() < 599) {
        $('#category-filter .filter-header .slide-trigger .icon').addClass('icon-plus');
    }

    $('.search-toggle').click(function(e) {
        e.preventDefault();
        if ($(this).hasClass('search-form-open')) {
            $(this).parents('.search-container').find('.search-form').submit();
        } else {
            $(this).parents('.search-container').find('.search-form').slideToggle(100);
            $(this).toggleClass('search-form-open');
        }
    });

    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        centerMode: true,
        focusOnSelect: true
    });

    $('.state').change(function () {
        $(this).parent().find('.state').each(function () {
            if (this.checked) {
                $(this).attr('aria-selected', 'true');
            } else {
                $(this).removeAttr('aria-selected');
            }
        });
    });

    $( document ).ready(function() {

      // MOBILE MENU
      $('header#header .header-branding .wrapper .section-menu ul li.active').click(function(event) {
        if (window.matchMedia('(max-width: 890px)').matches)
        {
          event.preventDefault();
          $('header#header .header-branding .wrapper .section-menu ul').toggleClass('open');
        }
      });

      $(window).on('resize', function(){
        $('header#header .header-branding .wrapper .section-menu ul li.active').click(function(event) {
          if (window.matchMedia('(max-width: 890px)').matches)
          {
            event.preventDefault();
            $('header#header .header-branding .wrapper .section-menu ul').toggleClass('open');
          }
        });
      });

    });



});

// lightbox
lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true
})
